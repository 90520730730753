import React, {createContext, useContext, useEffect} from 'react';
import './App.css';
import {Layout} from 'antd';
import MasterHeader from "./header/MasterHeader";
import {Route, Routes} from "react-router-dom";
import WelcomePage from "./pages/open/WelcomePage";
import RulesPage from "./pages/open/RulesPage";
import {Paths} from "./Paths";
import BackendClient from "./backend/BackendClient";
import GuidesPage from "./pages/open/GuidesPage";
import GuideBeginnerPage from "./pages/open/guides/GuideBeginnerPage";
import GuideTradersPage from "./pages/open/guides/GuideTradersPage";
import GuideVehiclesPage from "./pages/open/guides/GuideVehiclesPage";
import GuideReputationPage from "./pages/open/guides/GuideReputationPage";
import GuideBotsPage from "./pages/open/guides/GuideBotsPage";
import GuideMapAndMarkersPage from "./pages/open/guides/GuideMapAndMarkersPage";
import GuideHelicopterPage from "./pages/open/guides/GuideHelicopterPage";
import GuideMakingMoneyPage from "./pages/open/guides/GuideMakingMoneyPage";
import GuideHuntingPage from "./pages/open/guides/GuideHuntingPage";
import GuideTanningLeatherPage from "./pages/open/guides/GuideTanningLeatherPage";
import GuideFarmingPage from "./pages/open/guides/GuideFarmingPage";
import ItemShowCasingStyling from "./components/gameItems/ItemShowCasingStyling";
import GuideDirectConnectionPage from "./pages/open/guides/GuideDirectConnectionPage";
import GuideMetalsForgingPage from "./pages/open/guides/GuideMetalsForgingPage";
import DonatesPage from "./pages/open/DonatesPage";
import SteamLoginPage from "./pages/open/steam/login/SteamLoginPage";
import SteamLoginReturnPage from "./pages/open/steam/login/SteamLoginReturnPage";
import PlayerAreaPage from "./pages/open/PlayerAreaPage";
import RepairBagPage from "./pages/open/playerArea/RepairBagPage";
import GuideBulletCalibers from "./pages/open/guides/GuideBulletCalibers";
import AdminAreaPage from "./pages/open/AdminAreaPage";
import RebootServerPage from "./pages/open/adminArea/RebootServerPage";
import SetRunningValuesPage from "./pages/open/adminArea/SetRunningValuesPage";
import InGamePlayerDetailsPage from "./pages/open/adminArea/InGamePlayerDetailsPage";
import MapViewPage from "./pages/open/adminArea/MapViewPage";
import TeleportPage from "./pages/open/playerArea/TeleportPage";
import QuestsListing from "./pages/open/quests/QuestsListing";
import QuestsEditing from "./pages/open/quests/QuestsEditing";

const { Header, Content } = Layout;

export const BackendContext = createContext(new BackendClient())
export const ItemShowCasingStylingContext = createContext(new ItemShowCasingStyling())

function App() {
  const stylingContext = useContext(ItemShowCasingStylingContext)
  stylingContext.itemPresenterStyleConfigs = {
    imagePreview: false,
    imageWidth: "20%"
  }

  useEffect(() => {
    checkAndProcessRef()
  }, []); //

  return (
      <Layout className="layout">
        <Header>
          <MasterHeader />
        </Header>

        <Content >
          <Routes>
            <Route index element={<WelcomePage />} />
            <Route path={Paths.RulesPage} element={<RulesPage />} />

            <Route path={Paths.GuidesPage} element={<GuidesPage />} />
            <Route path={Paths.GuidesBeginnersPage} element={<GuideBeginnerPage />} />
            <Route path={Paths.GuidesTradersPage} element={<GuideTradersPage />} />
            <Route path={Paths.GuidesMapAndMarkersPage} element={<GuideMapAndMarkersPage />} />
            <Route path={Paths.GuidesVehiclesPage} element={<GuideVehiclesPage />} />
            <Route path={Paths.GuidesHelicopterPage} element={<GuideHelicopterPage />} />
            <Route path={Paths.GuidesReputationPage} element={<GuideReputationPage />} />
            <Route path={Paths.GuidesBotsPage} element={<GuideBotsPage />} />
            <Route path={Paths.GuidesMakingMoneyPage} element={<GuideMakingMoneyPage />} />
            <Route path={Paths.GuidesHuntingPage} element={<GuideHuntingPage />} />
            <Route path={Paths.GuidesLeatherTanningPage} element={<GuideTanningLeatherPage />} />
            <Route path={Paths.GuidesFarmingPage} element={<GuideFarmingPage />} />
            <Route path={Paths.GuidesDirectConnectionPage} element={<GuideDirectConnectionPage />} />
            <Route path={Paths.GuidesMetalsForgingPage} element={<GuideMetalsForgingPage />} />
            <Route path={Paths.GuidesBulletCalibers} element={<GuideBulletCalibers />} />

            <Route path={Paths.ItemsDonatePage} element={<DonatesPage />} />

            <Route path={Paths.PlayerAreaPage} element={<PlayerAreaPage />} />
            <Route path={Paths.PlayerAreaRepairBagPage} element={<RepairBagPage />} />
            <Route path={Paths.PlayerTeleportPage} element={<TeleportPage />} />

            <Route path={Paths.QuestsListingPage} element={<QuestsListing />} />
            <Route path={`${Paths.QuestEditingPage}/:id`} element={<QuestsEditing />} />

            <Route path={Paths.AdminAreaPage} element={<AdminAreaPage />} />
            <Route path={Paths.AdminAreaRebootServerPage} element={<RebootServerPage />} />
            <Route path={Paths.AdminAreaSetRunningValues} element={<SetRunningValuesPage />} />
            <Route path={Paths.AdminAreaInGamePlayerDetailsPage} element={<InGamePlayerDetailsPage />} />
            <Route path={Paths.AdminAreaMapViewPage} element={<MapViewPage />} />

            <Route path={Paths.LoginSteamPage} element={<SteamLoginPage />} />
            <Route path={Paths.LoginSteamReturnPage} element={<SteamLoginReturnPage />} />
          </Routes>
        </Content>
      </Layout>
  );
}

function checkAndProcessRef() {
  // Access the query parameters here
  const queryParams = new URLSearchParams(window.location.search);

  // Example: Get the value of 'param1'
  const refParam = queryParams.get('ref');

  if (refParam === null) {
    // Ref no specified
    return;
  }

  // @ts-ignore
  umami.track(`ref-${refParam}`);
}

export default App;
