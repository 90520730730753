enum ExpansionObjectiveCode {
  Target = 2,
  Travel = 3,
  Collection = 4,
  Delivery = 5,
  TreasureHunt = 6,
  AIPatrol = 7,
  AICamp = 8,
  AIVIP = 9,
  Action = 10,
}

export default ExpansionObjectiveCode;