import React, {useContext, useEffect, useState} from 'react';
import Title from "antd/es/typography/Title";
import {useNavigate} from "react-router-dom";
import {BackendContext} from "../../../App";
import {Paths} from "../../../Paths";
import ClearBody from "../../../components/ClearBody";
import {Button, Divider, List, Modal, notification, Spin} from "antd";
import {Messages} from "../../../backend/Messages";
import PublicTeleportingPOI from "../../../backend/models/teleport/PublicTeleportingPOI";
import FriendlyTerritoryTeleportingPOI from "../../../backend/models/teleport/FriendlyTerritoryTeleportingPOI";
import OptionalGenericPOIDTO from "../../../backend/models/teleport/OptionalGenericPOIDTO";
import {ReloadOutlined} from '@ant-design/icons';
import GenericPOI from "../../../backend/models/teleport/GenericPOI";
import DoTeleportRequestDTO from "../../../backend/models/teleport/DoTeleportRequestDTO";


type NotificationType = 'success' | 'info' | 'warning' | 'error';

type TeleportOption = {
  title: string,
  publicPOI: PublicTeleportingPOI | undefined,
  friendlyTerritoryPOI: FriendlyTerritoryTeleportingPOI | undefined,
}

function TeleportPage() {
  const backendContext = useContext(BackendContext);
  const [api, contextHolder] = notification.useNotification();
  const navigate = useNavigate();

  const [isModalOpen, setIsModalOpen] = useState(true);
  const [teleportOptions, setTeleportOptions] = useState<Array<TeleportOption>>([]);
  const [iAmAtPOI, setIAmAtPOI] = useState<OptionalGenericPOIDTO | undefined>(undefined);
  const [fetchedTeleportOptions, setFetchedTeleportOptions] = useState<Boolean>(false);


  const openNotificationWithIcon = (type: NotificationType, title: string, description: string) => {
    api[type]({
      message: title,
      description: description,
    });
  };



  useEffect(() => {
    if (! backendContext.hasPlayerAuth()) {
      navigate(Paths.LoginSteamPage);
    } else {
      if (fetchedTeleportOptions) {
        return;
      }

      backendContext.getMyTeleportingPOIs().then((teleportingPOIDTO) => {
        let teleportOptions: Array<TeleportOption> = [];
        teleportingPOIDTO.public.forEach((publicPOI) => {
          teleportOptions.push({
            title: publicPOI.name,
            publicPOI: publicPOI,
            friendlyTerritoryPOI: undefined
          });
        });
        teleportingPOIDTO.friendlyTerritories.forEach((friendlyTerritoryPOI) => {
          teleportOptions.push({
            title: friendlyTerritoryPOI.name,
            publicPOI: undefined,
            friendlyTerritoryPOI: friendlyTerritoryPOI
          });
        });
        setFetchedTeleportOptions(true);
        setTeleportOptions(teleportOptions);
        setIsModalOpen(false);

      }).catch((error) => {
        openNotificationWithIcon("error", Messages.UnknownErrorTitle, Messages.UnknownErrorDescription + " " + JSON.stringify(error));
      });
    }
  }, [backendContext, fetchedTeleportOptions, navigate, openNotificationWithIcon]);

  const refreshAmIAtPOI = () => {
    backendContext.amIAtATeleportingPOI().then((amIAtResponse) => {
      setIAmAtPOI(amIAtResponse)
    }).catch((error) => {
      openNotificationWithIcon("error", Messages.UnknownErrorTitle, Messages.UnknownErrorDescription + " " + JSON.stringify(error));
    });
  }

  const clickedTeleport = (poi: TeleportOption) => {
    setIsModalOpen(true);

    const request = new DoTeleportRequestDTO(
      poi.publicPOI?.name,
      poi.friendlyTerritoryPOI?.id
    );

    backendContext.doTeleport(request).then((commonResponse) => {
      setIsModalOpen(false);
      if (commonResponse.isError) {
        openNotificationWithIcon("error", "Erro", commonResponse.description);
        return;
      }

      openNotificationWithIcon("success", "Sucesso", commonResponse.description);

    }).catch((error) => {
      openNotificationWithIcon("error", Messages.UnknownErrorTitle, Messages.UnknownErrorDescription + " " + JSON.stringify(error));
    });
  }

  const clickedSetTerritoryTeleport = () => {
    setIsModalOpen(true);

    backendContext.setTerritorySpawnLocation().then((commonResponse) => {
      setIsModalOpen(false);
      if (commonResponse.isError) {
        openNotificationWithIcon("error", "Erro", commonResponse.description);
        return;
      }

      openNotificationWithIcon("success", "Sucesso", commonResponse.description);

    }).catch((error) => {
      openNotificationWithIcon("error", Messages.UnknownErrorTitle, Messages.UnknownErrorDescription + " " + JSON.stringify(error));
    });

  }

  let amIAtPOILabel: string = "";
  if (iAmAtPOI) {
    if (iAmAtPOI.poi) {
      if (iAmAtPOI.poi.public) {
        amIAtPOILabel = iAmAtPOI.poi.public.name;

      } else if (iAmAtPOI.poi.friendlyTerritory) {
        amIAtPOILabel = iAmAtPOI.poi.friendlyTerritory.name;

      } else {
        amIAtPOILabel = "ahn?";
      }

    } else {
      amIAtPOILabel = "Não";
    }
  }

  return (
    <>
      {contextHolder}
      <ClearBody content={
        <div>
          <Title>Sistema de teleporte</Title>
          <br />

          <Title level={4}>Para utilizar o sistema de teleporte, note:</Title>
          <ol>
            <li>Você deve estar dentro de um dos possíveis lugares que você pode se teleportar, ou seja, não é de qualquer lugar que você pode teleportar.</li>
          </ol>

          <Divider />
          <Title level={4}>
            Seu personagem se encontra em algum POI?: {amIAtPOILabel}
            <Button style={{marginLeft: 8}} type="primary" shape="circle" icon={<ReloadOutlined />} onClick={refreshAmIAtPOI} />

          </Title>


          <Divider />
          <Title level={4}>Os lugares que você pode teleportar são: <br /> (pressione o botão para teleportar)</Title>

          <List dataSource={teleportOptions.filter(option => option.publicPOI)} renderItem={item => (
              <List.Item style={{ border: 'none' }}>
                <Button type="primary" onClick={() => clickedTeleport(item)}>Local público: {item.title}</Button>
              </List.Item>
            )}
          />
          <List dataSource={teleportOptions.filter(option => option.friendlyTerritoryPOI)} renderItem={item => (
            <List.Item style={{ border: 'none' }}>
              <Button type="primary" onClick={() => clickedTeleport(item)}>Território: {item.title}</Button>
            </List.Item>
          )}
          />

          <Divider />
          <Title level={4}>Se você estiver em um território onde você é admin, você pode definir qual é o lugar onde o TP irá colocar quem se teleportar para este território. Utilize o botão abaixo:</Title>
          <Button type="primary" onClick={clickedSetTerritoryTeleport}>Definir o local de TP para onde você está atualmente</Button>

          <Modal open={isModalOpen} centered={true} closable={false}
                 okButtonProps={{ style: { display: 'none' } }}
                 cancelButtonProps={{ style: { display: 'none' } }}
          >
            <div style={{textAlign: "center"}}>
              <Spin size="large" />
            </div>
          </Modal>

        </div>
      } />
    </>
  );
}

export default TeleportPage;