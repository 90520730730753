class DoTeleportRequestDTO {
  readonly public: string | undefined;
  readonly friendlyTerritoryId: number | undefined;

  constructor(publicPOI: string | undefined, friendlyTerritoryId: number | undefined) {
    this.public = publicPOI;
    this.friendlyTerritoryId = friendlyTerritoryId;
  }
}

export default DoTeleportRequestDTO;