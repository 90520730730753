import React from 'react';
import {Button} from "antd";
import {PlusCircleOutlined} from "@ant-design/icons";
import ExpansionQuestObjectiveAICampDTO
  from "../../../backend/models/quest/objectives/aicamp/ExpansionQuestObjectiveAICampDTO";
import ExpansionQuestObjectiveDTO from "../../../backend/models/quest/objectives/ExpansionQuestObjectiveDTO";
import ExpansionObjectiveCode from "../../../backend/models/quest/objectives/ExpansionObjectiveCode";
import ExpansionQuestObjectiveAICampComponent from "./ExpansionQuestObjectiveAICampComponent";

interface ExpansionQuestObjectiveAICampComponentProps {
  genericObjective: ExpansionQuestObjectiveDTO;
  onObjectiveChange: (updatedObjective: ExpansionQuestObjectiveDTO) => void;
}

function ExpansionQuestObjectiveSuperComponent(props: ExpansionQuestObjectiveAICampComponentProps) {
  var component: JSX.Element | undefined = undefined;
  if (props.genericObjective.objectiveType === ExpansionObjectiveCode.AICamp) {
    component = <ExpansionQuestObjectiveAICampComponent objective={props.genericObjective as ExpansionQuestObjectiveAICampDTO} onObjectiveChange={props.onObjectiveChange} />;
  } else {
    component = <Button type="primary" icon={<PlusCircleOutlined />} size={"middle"} onClick={() => {}} style={{marginBottom: 16}} />;
  }

  return (
    component
  );
}

export default ExpansionQuestObjectiveSuperComponent;