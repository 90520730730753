import React, {useContext, useEffect, useState} from 'react';
import Title from "antd/es/typography/Title";
import {useNavigate, useParams} from "react-router-dom";
import {BackendContext} from "../../../App";
import {Paths} from "../../../Paths";
import ClearBody from "../../../components/ClearBody";
import {Button, Input, InputNumber, Modal, notification, Spin, Switch, Tooltip} from "antd";
import {Messages} from "../../../backend/Messages";
import {EditOutlined, PlusCircleOutlined} from '@ant-design/icons';
import ExpansionQuestDTO from "../../../backend/models/quest/ExpansionQuestDTO";
import StringListEditor from "../../../components/listsEditor/StringListEditor";
import ExpansionQuestRewardListEditor from "../../../components/quests/ExpansionQuestRewardListEditor";
import ExpansionQuestRewardDTO from "../../../backend/models/quest/ExpansionQuestRewardDTO";
import ExpansionNPCListEditor from "../../../components/quests/npc/ExpansionNPCListEditor";
import ExpansionQuestNPCDTO from "../../../backend/models/quest/ExpansionQuestNPCDTO";
import IntListEditor from "../../../components/listsEditor/IntListEditor";
import {Collapse, CollapseProps} from "antd/lib";
import ExpansionQuestObjectiveWrapDTO from "../../../backend/models/quest/ExpansionQuestObjectiveWrapDTO";
import ExpansionQuestObjectiveDTO from "../../../backend/models/quest/objectives/ExpansionQuestObjectiveDTO";
import EnumUtils from "../../../utils/EnumUtils";
import ExpansionObjectiveCode from "../../../backend/models/quest/objectives/ExpansionObjectiveCode";
import ExpansionQuestObjectiveSuperComponent
  from "../../../components/quests/objectives/ExpansionQuestObjectiveSuperComponent";


type NotificationType = 'success' | 'info' | 'warning' | 'error';

function QuestsEditing() {
  const backendContext = useContext(BackendContext);
  const [api, contextHolder] = notification.useNotification();
  const navigate = useNavigate();

  const {id} = useParams<{id: string}>()

  const [isModalOpen, setIsModalOpen] = useState(true);
  const [isDescriptionModalOpen, setIsDescriptionModalOpen] = useState(false);
  const [questItemsModalOpen, setQuestItemsModalOpen] = useState(false);
  const [rewardsModalOpen, setRewardsModalOpen] = useState(false);
  const [questGiversModalOpen, setQuestGiversModalOpen] = useState(false);
  const [questTurnInsModalOpen, setQuestTurnInsModalOpen] = useState(false);
  const [preQuestIdsModalOpen, setPreQuestIdsModalOpen] = useState(false);

  const [quest, setQuest] = useState<ExpansionQuestDTO | undefined>(undefined);
  const [npcs, setNpcs] = useState<Array<ExpansionQuestNPCDTO> | undefined>(undefined);
  const [questObjectives, setQuestObjectives] = useState<Array<ExpansionQuestObjectiveDTO>>([]);
  const [items, setItems] = useState<CollapseProps['items']>([]);


  const openNotificationWithIcon = (type: NotificationType, title: string, description: string) => {
    api[type]({
      message: title,
      description: description,
    });
  };

  const updateObjective = (index: number, updatedObjective: ExpansionQuestObjectiveDTO) => {
    const updatedObjectives = [...questObjectives];
    updatedObjectives[index] = updatedObjective;
    setQuestObjectives(updatedObjectives);
    buildCollapseItems(updatedObjectives);
  }

  const buildCollapseItems = (newObjectives: Array<ExpansionQuestObjectiveDTO>) => {
    const newItems: CollapseProps['items'] = [];
    for (let i = 0; i < newObjectives.length; i++) {
      const objective = newObjectives[i];
      newItems.push({
        key: objective.id.toString(),
        label: `[${i+1}] ${EnumUtils.getEnumName(ExpansionObjectiveCode, objective.objectiveType)}`,
        children: <ExpansionQuestObjectiveSuperComponent genericObjective={objective} onObjectiveChange={(updatedObjective) => {updateObjective(i, updatedObjective)}}/>,
      });
    }
    setItems(newItems);
  };

  const buildObjectives = (rawObjectives: Array<ExpansionQuestObjectiveWrapDTO>) => {
    const objectives: Array<ExpansionQuestObjectiveDTO> = [];
    for (let i = 0; i < rawObjectives.length; i++) {
      let rawObjective = rawObjectives[i];
      let objective = ExpansionQuestObjectiveDTO.fromRawObject(rawObjective);
      if (objective != null) {
        objectives.push(objective);
      } else {
      }
    }
    console.log("Objectives len: " + objectives.length);
    setQuestObjectives(objectives);
    buildCollapseItems(objectives);
  };

  useEffect(() => {
    if (! backendContext.hasPlayerAuth()) {
      navigate(Paths.LoginSteamPage);
    } else {
      if (quest === undefined) {

        backendContext.getQuestForEdit(parseInt(id ?? "0")).then((quest) => {
          setQuest(quest);
          buildObjectives(quest.objectives);
          setIsModalOpen(false);
        }).catch((error) => {
          openNotificationWithIcon("error", Messages.UnknownErrorTitle, Messages.UnknownErrorDescription + " " + JSON.stringify(error));
          console.log("Error: " + error + ", " + JSON.stringify(error));
        });

        backendContext.getNPCs().then((npcs) => {
          setNpcs(npcs)
        }).catch((error) => {
          openNotificationWithIcon("error", Messages.UnknownErrorTitle, Messages.UnknownErrorDescription + " " + JSON.stringify(error));
          console.log("Error: " + error + ", " + JSON.stringify(error));
        });
      }
    }
  }, [backendContext, id, navigate, openNotificationWithIcon, quest]);

  const handleTypeChange = (value: number | null) => {
    setQuest({...quest!!, type: value ?? 0});
  };
  const handleTitleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setQuest({...quest!!, title: e.target.value});
  };
  const handleDescriptionsChange = (updatedDescriptions: string[]) => {
    setQuest({ ...quest!!, descriptions: updatedDescriptions });
  };
  const handleObjectiveText = (e: React.ChangeEvent<HTMLInputElement>) => {
    setQuest({...quest!!, objectiveText: e.target.value});
  };
  const handleRepeatable = (checked: boolean) => {
    setQuest({...quest!!, repeatable: checked});
  };
  const handleIsDaily = (checked: boolean) => {
    setQuest({...quest!!, isDailyQuest: checked});
  };
  const handleIsWeekly = (checked: boolean) => {
    setQuest({...quest!!, isWeeklyQuest: checked});
  };
  const handleCancelQuestOnPlayerDeath = (checked: boolean) => {
    setQuest({...quest!!, cancelQuestOnPlayerDeath: checked});
  };
  const handleAutoComplete = (checked: boolean) => {
    setQuest({...quest!!, autocomplete: checked});
  };
  const handleGroupQuest = (checked: boolean) => {
    setQuest({...quest!!, isGroupQuest: checked});
  };
  const handleObjectSetFileName = (e: React.ChangeEvent<HTMLInputElement>) => {
    setQuest({...quest!!, objectSetFileName: e.target.value});
  };
  const handleQuestItems = (updatedList: string[]) => {
    setQuest({ ...quest!!, questItems: updatedList });
  };
  const handleRewardsChanges = (updatedList: ExpansionQuestRewardDTO[]) => {
    setQuest({ ...quest!!, rewards: updatedList });
  };
  const handleNeedToSelectReward = (checked: boolean) => {
    setQuest({...quest!!, needToSelectReward: checked});
  };
  const handleRandomReward = (checked: boolean) => {
    setQuest({...quest!!, randomReward: checked});
  };
  const handleRandomRewardAmount = (value: number | null) => {
    setQuest({...quest!!, randomRewardAmount: value ?? 0});
  };
  const handleQuestGiverIdsChanges = (updatedList: number[]) => {
    setQuest({ ...quest!!, questGiverIDs: updatedList });
  };
  const handleQuestTurninsChanges = (updatedList: number[]) => {
    setQuest({ ...quest!!, questTurnInIDs: updatedList });
  };
  const handleIsAchievementChanges = (checked: boolean) => {
    setQuest({ ...quest!!, isAchievement: checked });
  };
  const handleReputationRewardChange = (value: number | null) => {
    setQuest({...quest!!, reputationReward: value ?? 0});
  };
  const handleReputationRequirementChange = (value: number | null) => {
    setQuest({...quest!!, reputationRequirement: value ?? 0});
  };
  const handlePreQuestIDsChanges = (updatedList: number[]) => {
    setQuest({ ...quest!!, preQuestIDs: updatedList });
  };
  const handleRequiredFactionChanges = (e: React.ChangeEvent<HTMLInputElement>) => {
    setQuest({...quest!!, requiredFaction: e.target.value});
  };
  const handleFactionRewardChanges = (e: React.ChangeEvent<HTMLInputElement>) => {
    setQuest({...quest!!, factionReward: e.target.value});
  };
  const handlePlayerNeedQuestItemsChanges = (checked: boolean) => {
    setQuest({ ...quest!!, playerNeedQuestItems: checked });
  };
  const handleDeleteQuestItemsChanges = (checked: boolean) => {
    setQuest({ ...quest!!, deleteQuestItems: checked });
  };
  const handleSequentialObjectivesChanges = (checked: boolean) => {
    setQuest({ ...quest!!, sequentialObjectives: checked });
  };
  const handleSuppressQuestLogOnCompletionChanges = (checked: boolean) => {
    setQuest({ ...quest!!, suppressQuestLogOnCompetion: checked });
  };
  const handleActiveChanges = (checked: boolean) => {
    setQuest({ ...quest!!, active: checked });
  };

  return (
    <>
      {contextHolder}
      <ClearBody content={
        <div>
          <Title> Configuração da Quest <u>{quest?.title}</u> </Title>
          <br />

          <table>
            <tbody>
            <tr>
              <td><Tooltip title="Não mexer"> Config version </Tooltip></td>
              <td><Input disabled={true} value={quest?.configVersion} size={"large"} htmlSize={70} draggable/></td>
            </tr>
            <tr>
              <td><Tooltip title="Não mexer"> Quest id </Tooltip></td>
              <td><Input disabled={true} value={quest?.id}/></td>
            </tr>
            <tr>
              <td><Tooltip title="Tipo da quest. ?! Melhor não mexer"> Type </Tooltip></td>
              <td><InputNumber value={quest?.type} min={1} max={99999999} onChange={handleTypeChange}/></td>
            </tr>
            <tr>
              <td><Tooltip title="Nome da quest"> Title </Tooltip></td>
              <td><Input value={quest?.title} onChange={handleTitleChange}/></td>
            </tr>
            <tr>
              <td><Tooltip title="Descrições. Textos são substituídos na quest"> Descriptions </Tooltip></td>
              <td><Input value={JSON.stringify(quest?.descriptions)} disabled={true}/></td>
              <td><Button type="primary" icon={<EditOutlined/>} size={"middle"} onClick={() => {
                setIsDescriptionModalOpen(true)
              }}/></td>
            </tr>
            <tr>
              <td><Tooltip title="Texto objetivo da quest"> Objective text </Tooltip></td>
              <td><Input value={quest?.objectiveText} onChange={handleObjectiveText}/></td>
            </tr>
            <tr>
              <td><Tooltip
                title="Id de outra quest que será ativada automaticamente ao término desta. -1=nenhuma. A ser implementado futuramente"> Follow
                up quest Id </Tooltip></td>
              <td><Input value={quest?.followUpQuestId} disabled={true}/></td>
            </tr>
            <tr>
              <td><Tooltip title="A quest é repetível, se pode ser feita mais de uma vez"> Repeatable </Tooltip></td>
              <td><Switch checked={quest?.repeatable} onChange={handleRepeatable}/></td>
            </tr>
            <tr>
              <td><Tooltip title="Se a quest é diária, ser feita 1x por dia"> Daily quest </Tooltip></td>
              <td><Switch checked={quest?.isDailyQuest} onChange={handleIsDaily}/></td>
            </tr>
            <tr>
              <td><Tooltip title="Se a quest é semanal, ser feita 1x por semana"> Weekly quest </Tooltip></td>
              <td><Switch checked={quest?.isWeeklyQuest} onChange={handleIsWeekly}/></td>
            </tr>
            <tr>
              <td><Tooltip title="Se a quest será cancelada caso o player morra"> Cancel quest on <br/> player death
              </Tooltip></td>
              <td><Switch checked={quest?.cancelQuestOnPlayerDeath} onChange={handleCancelQuestOnPlayerDeath}/></td>
            </tr>
            <tr>
              <td><Tooltip title="Quests que se auto completam não requerem que o player vá até algum NPC"> Auto
                complete </Tooltip></td>
              <td><Switch checked={quest?.autocomplete} onChange={handleAutoComplete}/></td>
            </tr>
            <tr>
              <td><Tooltip title="Quests em grupo podem ser feitas apenas por players em party"> Group quest </Tooltip>
              </td>
              <td><Switch checked={quest?.isGroupQuest} onChange={handleGroupQuest}/></td>
            </tr>
            <tr>
              <td><Tooltip title="Nome do mapa que será carregado quando um player com a quest se aproximar"> Object set
                filename </Tooltip></td>
              <td><Input value={quest?.objectSetFileName} size={"large"} htmlSize={50}
                         onChange={handleObjectSetFileName}/></td>
            </tr>
            <tr>
              <td><Tooltip title="? Não sei que faz esse parâmetro ?"> Quest items </Tooltip></td>
              <td><Input value={JSON.stringify(quest?.questItems)} disabled={true}/></td>
              <td><Button type="primary" icon={<EditOutlined/>} size={"middle"} onClick={() => {
                setQuestItemsModalOpen(true)
              }}/></td>
            </tr>
            <tr>
              <td><Tooltip title="Recompensas que o player irá ganhar"> Rewards </Tooltip></td>
              <td><Input value={JSON.stringify(quest?.rewards)} disabled={true}/></td>
              <td><Button type="primary" icon={<EditOutlined/>} size={"middle"} onClick={() => {
                setRewardsModalOpen(true)
              }}/></td>
            </tr>
            <tr>
              <td><Tooltip title="Se é necessário selecionar recompensa(s) ao completar a quest"> Need to select
                reward </Tooltip></td>
              <td><Switch checked={quest?.needToSelectReward} onChange={handleNeedToSelectReward}/></td>
            </tr>
            <tr>
              <td><Tooltip title="Se a recompensa será aleatória"> Random reward </Tooltip></td>
              <td><Switch checked={quest?.randomReward} onChange={handleRandomReward}/></td>
            </tr>
            <tr>
              <td><Tooltip
                title="Quantidade de items aleatórios que o player ganhará: -1 -> (selecionará 1 recompensa aleatoriamente respeitando as chances configuradas), 0 -> (nenhum), 1+ (não sei...)"> Random
                reward amount </Tooltip></td>
              <td><InputNumber value={quest?.randomRewardAmount} min={-1} max={0} onChange={handleRandomRewardAmount}/>
              </td>
            </tr>
            <tr>
              <td><Tooltip title="Comportamento de seleção as recompensas. Não sei o que esse parâmetro faz"> Reward
                behavior </Tooltip></td>
              <td><InputNumber value={quest?.rewardBehavior} disabled={true} min={-1} max={0}/>
              </td>
            </tr>
            <tr>
              <td><Tooltip title="Ids dos npcs que podem oferecer essa quest"> Quest giver ids </Tooltip></td>
              <td><Input value={JSON.stringify(quest?.questGiverIDs)} disabled={true}/></td>
              <td><Button type="primary" icon={<EditOutlined/>} size={"middle"} onClick={() => {
                setQuestGiversModalOpen(true)
              }}/></td>
            </tr>
            <tr>
              <td><Tooltip title="Ids dos npcs que podem completar essa quest"> Quest turn ins </Tooltip></td>
              <td><Input value={JSON.stringify(quest?.questTurnInIDs)} disabled={true}/></td>
              <td><Button type="primary" icon={<EditOutlined/>} size={"middle"} onClick={() => {
                setQuestTurnInsModalOpen(true)
              }}/></td>
            </tr>
            <tr>
              <td><Tooltip title="Não sei ao certo o que isso significa kkkk"> Is achievement </Tooltip></td>
              <td><Switch checked={quest?.isAchievement} onChange={handleIsAchievementChanges}/></td>
            </tr>
            <tr>
              <td><Tooltip title="Cor da quest"> Quest color </Tooltip></td>
              <td><Input
                style={{backgroundColor: `#${(quest?.questColor!! >>> 0).toString(16).padStart(8, '0').slice(2)}`}}
                value={quest?.questColor}/></td>
            </tr>
            <tr>
              <td><Tooltip title="Reputação de recompensa"> Reputation reward </Tooltip></td>
              <td><InputNumber value={quest?.reputationReward} min={0} max={99999999}
                               onChange={handleReputationRewardChange}/></td>
            </tr>
            <tr>
              <td><Tooltip title="Reputação necessária pra pegar a quest"> Reputation requirement </Tooltip></td>
              <td><InputNumber value={quest?.reputationRequirement} min={-1} max={99999999}
                               onChange={handleReputationRequirementChange}/></td>
            </tr>
            <tr>
              <td><Tooltip title="Id das quests que devem ser feitas antes dessa"> Pre quest ids </Tooltip></td>
              <td><Input value={JSON.stringify(quest?.preQuestIDs)} disabled={true}/></td>
              <td><Button type="primary" icon={<EditOutlined/>} size={"middle"} onClick={() => {
                setPreQuestIdsModalOpen(true)
              }}/></td>
            </tr>
            <tr>
              <td><Tooltip title="Facção requirida pra poder pegar a quest"> Required faction </Tooltip></td>
              <td><Input value={quest?.requiredFaction} onChange={handleRequiredFactionChanges}/></td>
            </tr>
            <tr>
              <td><Tooltip title="Recompensa facção"> Faction reward </Tooltip></td>
              <td><Input value={quest?.factionReward} onChange={handleFactionRewardChanges}/></td>
            </tr>
            <tr>
              <td><Tooltip title="Se o player precisa de items pra pegar a quest"> Player need quest items </Tooltip>
              </td>
              <td><Switch checked={quest?.playerNeedQuestItems} onChange={handlePlayerNeedQuestItemsChanges}/></td>
            </tr>
            <tr>
              <td><Tooltip title="Se os items requiridos pra pegar a quest serão deletados"> Delete quest
                items </Tooltip></td>
              <td><Switch checked={quest?.deleteQuestItems} onChange={handleDeleteQuestItemsChanges}/></td>
            </tr>
            <tr>
              <td><Tooltip title="Se os objetivos configurados devem ser realizadosde forma sequencial"> Sequential
                objectives </Tooltip></td>
              <td><Switch checked={quest?.sequentialObjectives} onChange={handleSequentialObjectivesChanges}/></td>
            </tr>
            <tr>
              <td><Tooltip title="Reputações de facções requiridas para pegar a quest"> Faction reputation <br/>
                requirements </Tooltip></td>
              <td><Input disabled={true} value={JSON.stringify(quest?.factionReputationRequirements)} size={"large"}
                         htmlSize={50}/></td>
            </tr>
            <tr>
              <td><Tooltip title="Recompensa de reputações de facções"> Faction reputation rewards </Tooltip></td>
              <td><Input disabled={true} value={JSON.stringify(quest?.factionReputationRewards)} size={"large"}
                         htmlSize={50}/></td>
            </tr>
            <tr>
              <td><Tooltip title="Se a quest não irá aparecer no quest log após ser completada"> Suppress quest log
                on <br/> completion </Tooltip></td>
              <td><Switch checked={quest?.suppressQuestLogOnCompetion}
                          onChange={handleSuppressQuestLogOnCompletionChanges}/></td>
            </tr>
            <tr>
              <td><Tooltip title="Se a quest está ativa (se vai aparecer no server)"> Active </Tooltip></td>
              <td><Switch checked={quest?.active} onChange={handleActiveChanges}/></td>
            </tr>
            <tr>
              <td><Tooltip title="Se a quest está ativa (se vai aparecer no server)"> Objetivos </Tooltip></td>
              <td style={{textAlign: "center"}}>
                <Button type="primary" icon={<PlusCircleOutlined />} size={"middle"} onClick={() => {}} />
                <Collapse style={{marginTop: 8}} items={items} defaultActiveKey={['1']} />
              </td>
            </tr>
            </tbody>
          </table>

          <Modal open={isDescriptionModalOpen} centered={true} closable={false}
                 // okButtonProps={{ style: { display: 'none' } }}
                 cancelButtonProps={{ style: { display: 'none' } }}
            onOk={() => {setIsDescriptionModalOpen(false)}}
          >
            <StringListEditor strings={quest?.descriptions ?? []} onStringsChange={handleDescriptionsChange} />
          </Modal>
          <Modal open={questItemsModalOpen} centered={true} closable={false}
                 cancelButtonProps={{ style: { display: 'none' } }}
                 onOk={() => {setQuestItemsModalOpen(false)}}
          >
            <StringListEditor strings={quest?.questItems ?? []} onStringsChange={handleQuestItems} />
          </Modal>
          <Modal open={rewardsModalOpen} centered={true} closable={false}
                 cancelButtonProps={{ style: { display: 'none' } }}
                 onOk={() => {setRewardsModalOpen(false)}}
          >
            <ExpansionQuestRewardListEditor rewards={quest?.rewards ?? []} onRewardsChange={handleRewardsChanges} />
          </Modal>
          <Modal open={questGiversModalOpen} centered={true} closable={false}
                 cancelButtonProps={{ style: { display: 'none' } }}
                 onOk={() => {setQuestGiversModalOpen(false)}}
          >
            <ExpansionNPCListEditor npcs={npcs ?? []} npcIDs={quest?.questGiverIDs ?? []} onNPCIdsChange={handleQuestGiverIdsChanges} />
          </Modal>
          <Modal open={questTurnInsModalOpen} centered={true} closable={false}
                 cancelButtonProps={{ style: { display: 'none' } }}
                 onOk={() => {setQuestTurnInsModalOpen(false)}}
          >
            <ExpansionNPCListEditor npcs={npcs ?? []} npcIDs={quest?.questTurnInIDs ?? []} onNPCIdsChange={handleQuestTurninsChanges} />
          </Modal>
          <Modal open={questItemsModalOpen} centered={true} closable={false}
                 cancelButtonProps={{ style: { display: 'none' } }}
                 onOk={() => {setQuestItemsModalOpen(false)}}
          >
            <StringListEditor strings={quest?.questItems ?? []} onStringsChange={handleQuestItems} />
          </Modal>
          <Modal open={preQuestIdsModalOpen} centered={true} closable={false}
                 cancelButtonProps={{ style: { display: 'none' } }}
                 onOk={() => {setPreQuestIdsModalOpen(false)}}
          >
            <IntListEditor ints={quest?.preQuestIDs ?? []} onIntsChange={handlePreQuestIDsChanges} />
          </Modal>


          <Modal open={isModalOpen} centered={true} closable={false}
                 okButtonProps={{ style: { display: 'none' } }}
                 cancelButtonProps={{ style: { display: 'none' } }}
          >
            <div style={{textAlign: "center"}}>
              <Spin size="large" />
            </div>
          </Modal>

        </div>
      } />
    </>
  );
}

export default QuestsEditing;