import React from 'react';
import {Button, Input, InputNumber, Switch, Tooltip} from "antd";
import ExpansionQuestObjectiveAICampAISpawnDTO
  from "../../../backend/models/quest/objectives/aicamp/ExpansionQuestObjectiveAICampAISpawnDTO";
import {Collapse, CollapseProps} from "antd/lib";
import WaypointEditor from "../../listsEditor/WaypointEditor";
import {DeleteOutlined, PlusCircleOutlined} from "@ant-design/icons";


interface ExpansionQuestObjectiveAICampSpawnEditorProps {
  spawn: ExpansionQuestObjectiveAICampAISpawnDTO;
  onSpawnChange: (updatedSpawn: ExpansionQuestObjectiveAICampAISpawnDTO) => void;
}

function ExpansionQuestObjectiveAICampSpawnEditor({ spawn, onSpawnChange }: ExpansionQuestObjectiveAICampSpawnEditorProps) {
  const addNewWaypoint = () => {
    spawn.waypoints.push([0, 0, 0]);
    onSpawnChange(spawn);
  };

  const deleteRow = (index: number) => {
    const updatedWaypoints = [...spawn.waypoints];
    updatedWaypoints.splice(index, 1);
    onSpawnChange({...spawn, waypoints: updatedWaypoints});
  };

  const waypointChanged = (wayPointIndex: number) => (waypoint: number[]) => {
    spawn.waypoints[wayPointIndex] = waypoint;
    onSpawnChange(spawn);
  }

  const buildItemsFromWaypoints = () =>{
    const collapseItem: CollapseProps['items'] = [];
    collapseItem.push({
        key: "1",
        label: "Waypoints",
      children:
        <div style={{textAlign: "center"}}>
          <Button type="primary" icon={<PlusCircleOutlined />} size={"middle"} onClick={addNewWaypoint}/>
          {spawn.waypoints.map((waypoint, waypointIndex) => (
            <div>
              <table style={{textAlign: "center", margin: "auto"}}>
                <tbody>
                <tr>
                  <td><WaypointEditor coords={waypoint} onCoordsChange={waypointChanged(waypointIndex)} /></td>
                  <td><Button type="primary" icon={<DeleteOutlined/>} size={"middle"} onClick={() => {deleteRow(waypointIndex)}} style={{backgroundColor: "red", marginLeft: 4}}/></td>
                </tr>
                </tbody>
              </table>

            </div>
          ))}
        </div>,
      });
    return collapseItem;
  };

  const handlePersistChanges = (checked: boolean) => {
    onSpawnChange({...spawn, persist: checked});
  };
  const handleFactionChanges = (e: React.ChangeEvent<HTMLInputElement>) => {
    onSpawnChange({...spawn, faction: e.target.value});
  };
  const handleFormationChanges = (e: React.ChangeEvent<HTMLInputElement>) => {
    onSpawnChange({...spawn, formation: e.target.value});
  };
  const handleFormationLoosenessChanges = (value: number | null) => {
    onSpawnChange({...spawn, formationLooseness: value ?? 0.0});
  };
  const handleLoadoutChanges = (e: React.ChangeEvent<HTMLInputElement>) => {
    onSpawnChange({...spawn, loadout: e.target.value});
  };
  const handleNumberOfAIChanges = (value: number | null) => {
    onSpawnChange({...spawn, numberOfAI: value ?? 0.0});
  };
  const handleBehaviorChanges = (e: React.ChangeEvent<HTMLInputElement>) => {
    onSpawnChange({...spawn, behaviour: e.target.value});
  };
  const handleSpeedChanges = (e: React.ChangeEvent<HTMLInputElement>) => {
    onSpawnChange({...spawn, speed: e.target.value});
  };
  const handleUnderThreatSpeedChanges = (e: React.ChangeEvent<HTMLInputElement>) => {
    onSpawnChange({...spawn, underThreatSpeed: e.target.value});
  };
  const handleCanBeLootedChanges = (checked: boolean) => {
    onSpawnChange({...spawn, canBeLooted: checked});
  };
  const handleUnlimitedReloadChanges = (checked: boolean) => {
    onSpawnChange({...spawn, unlimitedReload: checked});
  };
  const handleSniperProneDistanceThresholdChanges = (value: number | null) => {
    onSpawnChange({...spawn, sniperProneDistanceThreshold: value ?? 0.0});
  };
  const handleAccuracyMinChanges = (value: number | null) => {
    onSpawnChange({...spawn, accuracyMin: value ?? 0.0});
  };
  const handleAccuracyMaxChanges = (value: number | null) => {
    onSpawnChange({...spawn, accuracyMax: value ?? 0.0});
  };
  const handleThreatDistanceLimitChanges = (value: number | null) => {
    onSpawnChange({...spawn, threatDistanceLimit: value ?? 0.0});
  };
  const handleNoiseInvestigationDistanceLimitChanges = (value: number | null) => {
    onSpawnChange({...spawn, noiseInvestigationDistanceLimit: value ?? 0.0});
  };
  const handleDamageMultiplierChanges = (value: number | null) => {
    onSpawnChange({...spawn, damageMultiplier: value ?? 0.0});
  };
  const handleDamageReceivedMultiplierLimitChanges = (value: number | null) => {
    onSpawnChange({...spawn, damageReceivedMultiplier: value ?? 0.0});
  };
  const handleMinDistRadiusChanges = (value: number | null) => {
    onSpawnChange({...spawn, minDistRadius: value ?? 0.0});
  };
  const handleMaxDistRadiusChanges = (value: number | null) => {
    onSpawnChange({...spawn, maxDistRadius: value ?? 0.0});
  };
  const handleDespawnRadiusChanges = (value: number | null) => {
    onSpawnChange({...spawn, despawnRadius: value ?? 0.0});
  };
  const handleMinSpreadRadiusChanges = (value: number | null) => {
    onSpawnChange({...spawn, minSpreadRadius: value ?? 0.0});
  };
  const handleMaxSpreadRadiusChanges = (value: number | null) => {
    onSpawnChange({...spawn, maxSpreadRadius: value ?? 0.0});
  };
  const handleChanceChanges = (value: number | null) => {
    onSpawnChange({...spawn, chance: value ?? 0.0});
  };
  const handleWaypointInterpolationChanges = (e: React.ChangeEvent<HTMLInputElement>) => {
    onSpawnChange({...spawn, waypointInterpolation: e.target.value});
  };
  const handleDespawnTimeChanges = (value: number | null) => {
    onSpawnChange({...spawn, despawnTime: value ?? 0.0});
  };
  const handleRespawnTimeChanges = (value: number | null) => {
    onSpawnChange({...spawn, respawnTime: value ?? 0.0});
  };
  const handleUseRandomWaypointAsStartPointChanges = (checked: boolean) => {
    onSpawnChange({...spawn, useRandomWaypointAsStartPoint: checked});
  };

  return (
    <div style={{textAlign: "left"}}>
      <table>
      <tbody>
        <tr>
          <td><Tooltip title="Nome do spawn"> Name </Tooltip></td>
          <td><Input value={spawn.name} size={"large"} htmlSize={70}/></td>
        </tr>
        <tr>
          <td><Tooltip title="Se o spawn deve ser persistido. ??? tb não sei ao certo o que isso faz"> Persist </Tooltip></td>
          <td><Switch checked={spawn?.persist} onChange={handlePersistChanges}/></td>
        </tr>
        <tr>
          <td><Tooltip title="Facção do bot"> Faction </Tooltip></td>
          <td><Input value={spawn?.faction} size={"large"} htmlSize={50} onChange={handleFactionChanges}/></td>
        </tr>
        <tr>
          <td><Tooltip title="Formação dos bot"> Formation </Tooltip></td>
          <td><Input value={spawn?.formation} size={"large"} htmlSize={50} onChange={handleFormationChanges}/></td>
        </tr>
        <tr>
          <td><Tooltip title="O quanto bots individuais podem se distanciar da formação do grupo"> Formation looseness </Tooltip></td>
          <td><InputNumber value={spawn?.formationLooseness} min={0} max={999999} onChange={handleFormationLoosenessChanges}/></td>
        </tr>
        <tr>
          <td><Tooltip title="Nome da configuração de vestimenta do bot"> Loadout </Tooltip></td>
          <td><Input value={spawn?.loadout} size={"large"} htmlSize={50} onChange={handleLoadoutChanges}/></td>
        </tr>
        <tr>
          <td><Tooltip title="Entidades de Bots que podem ser spawnadas"> Units </Tooltip></td>
          <td><Input value={JSON.stringify(spawn?.units)} disabled={true} size={"large"} htmlSize={50} /></td>
        </tr>
        <tr>
          <td><Tooltip title="Número de bots que pertence a esse spawn"> Number of AI </Tooltip></td>
          <td><InputNumber value={spawn?.numberOfAI} min={1} max={999999} onChange={handleNumberOfAIChanges}/></td>
        </tr>
        <tr>
          <td><Tooltip title="Comportamento do bot"> Behavior </Tooltip></td>
          <td><Input value={spawn?.behaviour} size={"large"} htmlSize={50} onChange={handleBehaviorChanges} /></td>
        </tr>
        <tr>
          <td><Tooltip title="Velocidade do bot"> Speed </Tooltip></td>
          <td><Input value={spawn?.speed} size={"large"} htmlSize={50} onChange={handleSpeedChanges} /></td>
        </tr>
        <tr>
          <td><Tooltip title="Velocidade do bot quando sob ameaça"> Under thread speed </Tooltip></td>
          <td><Input value={spawn?.underThreatSpeed} size={"large"} htmlSize={50} onChange={handleUnderThreatSpeedChanges} /></td>
        </tr>
        <tr>
          <td><Tooltip title="Se o itens do bot podem ser looteados"> Can be looted </Tooltip></td>
          <td><Switch checked={spawn?.canBeLooted} onChange={handleCanBeLootedChanges}/></td>
        </tr>
        <tr>
          <td><Tooltip title="Se o bot pode recarregar a arma infinitamente"> Unlimited reload </Tooltip></td>
          <td><Switch checked={spawn?.unlimitedReload} onChange={handleUnlimitedReloadChanges}/></td>
        </tr>
        <tr>
          <td><Tooltip title="Limiar em metros para o bot deitar e snipar?"> Sniper prone distance threshold </Tooltip></td>
          <td><InputNumber value={spawn?.sniperProneDistanceThreshold} min={1} max={999999} onChange={handleSniperProneDistanceThresholdChanges}/></td>
        </tr>
        <tr>
          <td><Tooltip title="Acurácia mínima para a mira dos bots. 0.0 -> Segue a definição padrão"> Accuracy min </Tooltip></td>
          <td><InputNumber value={spawn?.accuracyMin} min={0} max={1} step={0.01} onChange={handleAccuracyMinChanges}/></td>
        </tr>
        <tr>
          <td><Tooltip title="Acurácia máxima para a mira dos bots. 0.0 -> Segue a definição padrão"> Accuracy max </Tooltip></td>
          <td><InputNumber value={spawn?.accuracyMax} min={0} max={1} step={0.01} onChange={handleAccuracyMaxChanges}/></td>
        </tr>
        <tr>
          <td><Tooltip title="Distância limite para os bots engajarem em combate"> Threat distance limit </Tooltip></td>
          <td><InputNumber value={spawn?.threatDistanceLimit} min={1} max={999999} onChange={handleThreatDistanceLimitChanges}/></td>
        </tr>
        <tr>
          <td><Tooltip title="Distância limite para investigar barulhos"> Noise investigation distance limit </Tooltip></td>
          <td><InputNumber value={spawn?.noiseInvestigationDistanceLimit} min={-1} max={999999} onChange={handleNoiseInvestigationDistanceLimitChanges}/></td>
        </tr>
        <tr>
          <td><Tooltip title="Multiplicar do dano dado pelo bot. 1.0 -> padrão (100%)"> Damage multiplier </Tooltip></td>
          <td><InputNumber value={spawn?.damageMultiplier} min={0} max={999999} step={0.01} onChange={handleDamageMultiplierChanges}/></td>
        </tr>
        <tr>
          <td><Tooltip title="Multiplicar do dano recebido pelo bot. 1.0 -> padrão (100%)"> Damage received multiplier </Tooltip></td>
          <td><InputNumber value={spawn?.damageReceivedMultiplier} min={0} max={999999} step={0.01} onChange={handleDamageReceivedMultiplierLimitChanges}/></td>
        </tr>
        <tr>
          <td><Tooltip title="Distância mínima (de algum player) para o bot spawnar"> Min dist radius </Tooltip></td>
          <td><InputNumber value={spawn?.minDistRadius} min={0} max={999999} onChange={handleMinDistRadiusChanges}/></td>
        </tr>
        <tr>
          <td><Tooltip title="Distância máxima (de algum player) para o bot spawnar"> Max dist radius </Tooltip></td>
          <td><InputNumber value={spawn?.maxDistRadius} min={0} max={999999} onChange={handleMaxDistRadiusChanges}/></td>
        </tr>
        <tr>
          <td><Tooltip title="Distância para o bot despawnar"> Despawn radius </Tooltip></td>
          <td><InputNumber value={spawn?.despawnRadius} min={0} max={999999} onChange={handleDespawnRadiusChanges}/></td>
        </tr>
        <tr>
          <td><Tooltip title="Raio mínimo de espalhamento"> Min spread radius </Tooltip></td>
          <td><InputNumber value={spawn?.minSpreadRadius} min={0} max={999999} onChange={handleMinSpreadRadiusChanges}/></td>
        </tr>
        <tr>
          <td><Tooltip title="Raio máximo de espalhamento"> Max spread radius </Tooltip></td>
          <td><InputNumber value={spawn?.maxSpreadRadius} min={0} max={999999} onChange={handleMaxSpreadRadiusChanges}/></td>
        </tr>
        <tr>
          <td><Tooltip title="Probabilidade de spawnar esses bots"> Chance </Tooltip></td>
          <td><InputNumber value={spawn?.chance} min={0} max={1} step={0.01} onChange={handleChanceChanges}/></td>
        </tr>
        <tr>
          <td><Tooltip title="Modo de interpolação entre os pontos no mapa"> Waypoint interpolation </Tooltip></td>
          <td><Input value={spawn?.waypointInterpolation} size={"large"} htmlSize={50} onChange={handleWaypointInterpolationChanges} /></td>
        </tr>
        <tr>
          <td><Tooltip title="Tempo em segundos para despawnar os bots"> Despawn time </Tooltip></td>
          <td><InputNumber value={spawn?.despawnTime} min={1} max={999999} onChange={handleDespawnTimeChanges}/></td>
        </tr>
        <tr>
          <td><Tooltip title="Tempo em segundos para respawnar os bots"> Respawn time </Tooltip></td>
          <td><InputNumber value={spawn?.respawnTime} min={1} max={999999} onChange={handleRespawnTimeChanges}/></td>
        </tr>
        <tr>
          <td><Tooltip title="O ponto inicial de locomoção dos bots é um waypoint aleatório?"> Use random waypoint as start point </Tooltip></td>
          <td><Switch checked={spawn?.useRandomWaypointAsStartPoint} onChange={handleUseRandomWaypointAsStartPointChanges}/></td>
        </tr>
        <tr>
          <td colSpan={2}><Collapse style={{marginTop: 8}} items={buildItemsFromWaypoints()} /></td>
        </tr>
      </tbody>
      </table>
    </div>
);
}

export default ExpansionQuestObjectiveAICampSpawnEditor;