import React from 'react';
import {Button, Divider, Input, InputNumber, Tooltip} from "antd";
import {DeleteOutlined, PlusCircleOutlined} from "@ant-design/icons";
import ExpansionQuestRewardDTO from "../../backend/models/quest/ExpansionQuestRewardDTO";

interface ExpansionQuestRewardListEditorProps {
  rewards: ExpansionQuestRewardDTO[];
  onRewardsChange: (updatedRewards: ExpansionQuestRewardDTO[]) => void;
}

function ExpansionQuestRewardListEditor({ rewards, onRewardsChange }: ExpansionQuestRewardListEditorProps) {
  const addNewReward = () => {
    onRewardsChange([...rewards, {className: '', questID: -1, amount: 1, chance: 0.0, attachments: [], damagePercent: 0, healthPercent: 0}]);
  };
  const deleteReward = (index: number) => {
    rewards.splice(index, 1);
    onRewardsChange(rewards);
  };
  const handleRewardClassName = (i: number) => (e: React.ChangeEvent<HTMLInputElement>) => {
    rewards[i] = { ...rewards[i]!!, className: e.target.value }
    onRewardsChange(rewards);
  };
  const handleAmount = (i: number) => (value: number | null) => {
    rewards[i] = { ...rewards[i]!!, amount: value ?? 0 }
    onRewardsChange(rewards);
  };
  const handleDamagePercent = (i: number) => (value: number | null) => {
    rewards[i] = { ...rewards[i]!!, damagePercent: value ?? 0 }
    onRewardsChange(rewards);
  };
  const handleHealthPercent = (i: number) => (value: number | null) => {
    rewards[i] = { ...rewards[i]!!, healthPercent: value ?? 0 }
    onRewardsChange(rewards);
  };
  const handleQuestId = (i: number) => (value: number | null) => {
    rewards[i] = { ...rewards[i]!!, questID: value ?? 0 }
    onRewardsChange(rewards);
  };
  const handleChance = (i: number) => (value: number | null) => {
    rewards[i] = { ...rewards[i]!!, chance: value ?? 0 }
    onRewardsChange(rewards);
  };

  return (
    <div style={{textAlign: "center"}}>
      <Button type="primary" icon={<PlusCircleOutlined />} size={"middle"} onClick={addNewReward} style={{marginBottom: 16}} />
      {rewards.map((reward, index) => (
        <div key={index} style={{textAlign: "left"}}>
          <table>
            <tbody>
            <tr>
              <td><Tooltip title="No do item da recompensa"> ClassName </Tooltip></td>
              <td><Input key={index} value={reward.className} onChange={handleRewardClassName(index)} htmlSize={50}/>
              </td>
              <td rowSpan={6}><Button type="primary" icon={<DeleteOutlined/>} size={"middle"} onClick={() => {
                deleteReward(index)
              }} style={{backgroundColor: "red", marginLeft: 4}}/></td>
            </tr>
            <tr>
              <td><Tooltip title="Quantidade"> Amount </Tooltip></td>
              <td><InputNumber value={reward?.amount} min={1} max={99999999} onChange={handleAmount(index)} />
              </td>
            </tr>
            <tr>
              <td><Tooltip title="Dano ao item da recompensa"> Damage % </Tooltip></td>
              <td><InputNumber value={reward?.damagePercent} min={0} max={100} onChange={handleDamagePercent(index)}/>
              </td>
            </tr>
            <tr>
              <td><Tooltip title="HP da recompensa"> Health % </Tooltip></td>
              <td><InputNumber value={reward?.healthPercent} min={0} max={100} onChange={handleHealthPercent(index)}/>
              </td>
            </tr>
            <tr>
              <td><Tooltip title="Recompensa que o player poderá ganhar. -1 -> Quest nenhuma"> Quest id </Tooltip></td>
              <td><InputNumber value={reward?.questID} min={-1} max={999999999} onChange={handleQuestId(index)}/>
              </td>
            </tr>
            <tr>
              <td><Tooltip title="Chance de receber tal recompensa. Valor entre 0 e 1.0"> Chance </Tooltip></td>
              <td><InputNumber value={reward?.chance} min={0} max={1} step={0.01} onChange={handleChance(index)}/>
              </td>
            </tr>
            </tbody>
          </table>
          <div style={{display: 'flex', alignItems: 'center'}}>


          </div>
          <Divider style={{margin: 8}}/>
        </div>
      ))}
    </div>
  );
}

export default ExpansionQuestRewardListEditor;