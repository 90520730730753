import React, {useContext, useEffect} from 'react';
import ClearBody from "../../components/ClearBody";
import Title from "antd/es/typography/Title";
import {BackendContext} from "../../App";
import {useNavigate} from "react-router-dom";
import {Paths} from "../../Paths";


function PlayerAreaPage() {
  const backendContext = useContext(BackendContext);
  const navigate = useNavigate();

  useEffect(() => {
    if (! backendContext.hasPlayerAuth()) {
      navigate(Paths.WelcomePage);
    }
  }, [backendContext, navigate]);

  return (
    <ClearBody content={
      <div>
        <Title>Área do jogador</Title>
        <br />

        <Title level={3}>Utilidades</Title>
        <ul>
          <li><a href={Paths.PlayerAreaRepairBagPage}>Reparar bolsa</a></li>
          <li><a href={Paths.PlayerTeleportPage}>Sistema de Teleport</a></li>
        </ul>
      </div>
    } />
  );
}

export default PlayerAreaPage;