import React from 'react';
import {Button, Divider, InputNumber} from "antd";
import {DeleteOutlined, PlusCircleOutlined} from "@ant-design/icons";

interface IntListEditorProps {
  ints: number[];
  onIntsChange: (updatedInts: number[]) => void;
}

function IntListEditor({ ints, onIntsChange }: IntListEditorProps) {
  const addNewRow = () => {
    onIntsChange([...ints, 0]);
  };
  const deleteRow = (index: number) => {
    const updatedInts = [...ints];
    updatedInts.splice(index, 1);
    onIntsChange(updatedInts);
  };
  const handleInputChange = (index: number) => (value: number | null) => {
    const updatedInts = [...ints];
    updatedInts[index] = value ?? 0;
    onIntsChange(updatedInts);
  };

  return (
    <div style={{textAlign: "center"}}>
      <Button type="primary" icon={<PlusCircleOutlined />} size={"middle"} onClick={addNewRow} style={{marginBottom: 16}} />
      {ints.map((int, index) => (
        <div key={index}>
          <div  style={{ display: 'flex', alignItems: 'center' }}>
            <InputNumber value={int} min={-1} max={99999999} onChange={handleInputChange(index)} />
            <Button type="primary" icon={<DeleteOutlined />} size={"middle"} onClick={() => {deleteRow(index)}} style={{backgroundColor: "red", marginLeft: 4}} />
          </div>
          <Divider style={{margin: 8}}/>
        </div>
      ))}
    </div>
  );
}

export default IntListEditor;