import React, {useState} from 'react';
import {Button, Divider, Dropdown, Input, InputNumber, MenuProps, Space} from "antd";
import {DeleteOutlined, DownOutlined} from "@ant-design/icons";
import ExpansionQuestNPCDTO from "../../../backend/models/quest/ExpansionQuestNPCDTO";


interface ExpansionNPCListEditorProps {
  npcs: ExpansionQuestNPCDTO[];
  npcIDs: number[];
  onNPCIdsChange: (updatedNPCIds: number[]) => void;
}

function ExpansionNPCListEditor({ npcs, npcIDs, onNPCIdsChange }: ExpansionNPCListEditorProps) {

  const buildItems = ():  MenuProps['items'] => {
    let items: MenuProps['items'] = [];
    npcs.forEach((npc) => {
      // @ts-ignore
      items.push({
        key: npc.id.toString(),
        label: npc.npcName,
      });
    });

    return items;
  };
  const [items, setItems] = useState<MenuProps['items']>(buildItems());

  const getNameOfNPC = (npcId: number): string => {
    for (let npc of npcs) {
      if (npc.id === npcId) {
        return npc.npcName;
      }
    }

    return "?";
  };

  const onClick: MenuProps['onClick'] = ({ key }) => {
    const npcId = parseInt(key.toString());
    const updatedNpcIds = [...npcIDs, npcId];
    onNPCIdsChange(updatedNpcIds);
  };

  const deleteRow = (index: number) => {
    const updatedNpcIds = [...npcIDs];
    updatedNpcIds.splice(index, 1);
    onNPCIdsChange(updatedNpcIds);
  };

  return (
    <div style={{textAlign: "center"}}>
      <Dropdown menu={{items, onClick}} >
        <Button style={{marginBottom: 8}}>
          <Space>
            NPC
            <DownOutlined />
          </Space>
        </Button>
      </Dropdown>

      {npcIDs.map((npcId, index) => (
        <div key={index}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Input value={getNameOfNPC(npcId)} disabled={true} />
            <InputNumber value={npcId} disabled={true} />
            <Button type="primary" icon={<DeleteOutlined />} size={"middle"} onClick={() => {deleteRow(index)}} style={{backgroundColor: "red", marginLeft: 4}} />
          </div>
          <Divider style={{margin: 8}}/>
        </div>
      ))}
    </div>
  );
}

export default ExpansionNPCListEditor;