import React, {useContext, useEffect, useState} from 'react';
import Title from "antd/es/typography/Title";
import {Link, useNavigate} from "react-router-dom";
import {BackendContext} from "../../../App";
import {Paths} from "../../../Paths";
import ClearBody from "../../../components/ClearBody";
import {List, Modal, notification, Skeleton, Spin} from "antd";
import {Messages} from "../../../backend/Messages";
import ExpansionQuestDTO from "../../../backend/models/quest/ExpansionQuestDTO";


type NotificationType = 'success' | 'info' | 'warning' | 'error';

function QuestsListing() {
  const backendContext = useContext(BackendContext);
  const [api, contextHolder] = notification.useNotification();
  const navigate = useNavigate();

  const [isModalOpen, setIsModalOpen] = useState(true);

  const [quests, setQuests] = useState<Array<ExpansionQuestDTO> | undefined>(undefined);


  const openNotificationWithIcon = (type: NotificationType, title: string, description: string) => {
    api[type]({
      message: title,
      description: description,
    });
  };

  useEffect(() => {
    if (! backendContext.hasPlayerAuth()) {
      navigate(Paths.LoginSteamPage);
    } else {
      if (quests === undefined) {

        backendContext.getQuests().then((quests) => {
          setQuests(quests);
          setIsModalOpen(false);
        }).catch((error) => {
          openNotificationWithIcon("error", Messages.UnknownErrorTitle, Messages.UnknownErrorDescription + " " + JSON.stringify(error));
        });
        return;
      }
    }
  }, [backendContext, navigate, openNotificationWithIcon, quests]);

  const refreshAmIAtPOI = () => {
    backendContext.amIAtATeleportingPOI().then((amIAtResponse) => {
    }).catch((error) => {
      openNotificationWithIcon("error", Messages.UnknownErrorTitle, Messages.UnknownErrorDescription + " " + JSON.stringify(error));
    });
  }

  return (
    <>
      {contextHolder}
      <ClearBody content={
        <div>
          <Title>Quests do server</Title>
          <br />

          {quests && (
            <List dataSource={quests} renderItem={item => (
              <List.Item
                actions={[<Link key={`${Paths.QuestEditingPage}/${item.id}`} to={`${Paths.QuestEditingPage}/${item.id}`}>Editar</Link>]}
              >
                <Skeleton title={false} loading={false}>
                  <div style={{width: 50}}>{item.id}</div>
                  <List.Item.Meta
                    // avatar={<Avatar src={item.picture.large} />}
                    title={item.title}
                    description={item.objectiveText}
                  />
                  {/*<div>content</div>*/}
                </Skeleton>
              </List.Item>
            )}
            />
          )}

          {/*<Button type="primary" onClick={clickedSetTerritoryTeleport}>Definir o local de TP para onde você está atualmente</Button>*/}

          <Modal open={isModalOpen} centered={true} closable={false}
                 okButtonProps={{ style: { display: 'none' } }}
                 cancelButtonProps={{ style: { display: 'none' } }}
          >
            <div style={{textAlign: "center"}}>
              <Spin size="large" />
            </div>
          </Modal>

        </div>
      } />
    </>
  );
}

export default QuestsListing;