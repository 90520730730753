import axios from "axios";
import GameServerPublicInfo from "./models/GameServerPublicInfo";
import CommonResponseWithPlayerAuthInfo from "./models/CommonResponseWithPlayerAuthInfo";
import PlayerAuthInfo from "./models/PlayerAuthInfo";
import Cookies from 'js-cookie';
import CommonResponseWithSteamPlayerPublicInfo from "./models/CommonResponseWithSteamPlayerPublicInfo";
import CommonResponse from "./models/CommonResponse";
import PlayingPlayersResponse from "./models/PlayingPlayersResponse";
import PlayerAvailablePOIsDTO from "./models/teleport/PlayerAvailablePOIsDTO";
import OptionalGenericPOIDTO from "./models/teleport/OptionalGenericPOIDTO";
import DoTeleportRequestDTO from "./models/teleport/DoTeleportRequestDTO";
import PublicTerritoryDTO from "./models/territory/PublicTerritoryDTO";
import ExpansionQuestDTO from "./models/quest/ExpansionQuestDTO";
import ExpansionQuestNPCDTO from "./models/quest/ExpansionQuestNPCDTO";

class BackendClient {
  private static readonly GetServerPublicInfoPath = "/api/server/getServerPublicInfo"
  private static readonly TriggerRebootPath = "/api/player/triggerServerUpdateForcedManually"
  private static readonly SetServerRunningValues = "/api/player/setServerRunningValues"
  private static readonly ListPlayingPlayers = "/api/player/listPlayingPlayers"
  private static readonly LoginWithSteamReturnInfoPath = "/api/player/loginWithSteamReturnInfo"
  private static readonly RetrieveMySteamInfo = "/api/player/retrieveMySteamInfo"
  private static readonly RepairMyVipBag = "/api/player/repairMyVipBag"
  private static readonly GetMyTeleportingPOIs = "/api/player/getMyTeleportingPOIs"
  private static readonly AmIAtATeleportingPOI = "/api/player/amIAtATeleportingPOI"
  private static readonly DoTeleport = "/api/player/doTeleport"
  private static readonly SetTerritorySpawnLocation = "/api/player/setTerritorySpawnLocation"
  private static readonly ListTerritories = "/api/player/listTerritories"
  private static readonly GetQuests = "/api/quest/getQuests"
  private static readonly GetQuestForEdit = "/api/quest/getQuestForEdit"
  private static readonly GetNPCs = "/api/quest/getNPCs"

  private readonly backendUrl;
  private readonly CK_authToken = 'auth-token';
  private readonly CK_authEntity = 'auth-entity';
  private readonly CK_loggedUserRoles = 'logged-user-roles';

  private readonly ROLE_ADMIN = 'ADMIN';
  private readonly ROLE_QUESTER = 'QUESTER';

  private authListeners: Map<string, (playerAuth: PlayerAuthInfo | null) => void> = new Map();

  private authToken: string | null = null;
  private authEntity: string | null = null;
  private loggedUserRoles: Set<string> | null = null;

  constructor() {
    this.backendUrl = process.env.REACT_APP_BACKEND_URL
    const authToken = Cookies.get(this.CK_authToken)
    if (authToken !== undefined) {
      this.authToken = authToken;
    }
    const authEntity = Cookies.get(this.CK_authEntity);
    if (authEntity !== undefined) {
      this.authEntity = authEntity;
    }

    const loggedUserRoles = Cookies.get(this.CK_loggedUserRoles);
    if (loggedUserRoles !== undefined) {
      this.loggedUserRoles = new Set(JSON.parse(loggedUserRoles));
    }
  }

  wireNewPlayerAuthListener(listenerName: string, callbackFunction: (playerAuth: PlayerAuthInfo | null) => void) {
    this.authListeners.set(listenerName, callbackFunction);
  }

  private notifyAuthListeners(playerAuth: PlayerAuthInfo | null) {
    this.authListeners.forEach(callbackFunction => {
      callbackFunction(playerAuth);
    });
  }

  hasPlayerAuth(): boolean {
    return this.authToken !== null && this.authEntity !== null;
  }

  isAdmin(): boolean {
    return this.loggedUserRoles !== null && this.loggedUserRoles.has(this.ROLE_ADMIN);
  }

  isQuester(): boolean {
    return this.loggedUserRoles !== null && (this.loggedUserRoles.has(this.ROLE_QUESTER) || this.loggedUserRoles.has(this.ROLE_ADMIN));
  }

  wirePlayerAuth(auth: PlayerAuthInfo) {
    Cookies.set(this.CK_authToken, auth.token, { expires: new Date(auth.validity) });
    Cookies.set(this.CK_authEntity, auth.entity, { expires: new Date(auth.validity) });
    Cookies.set(this.CK_loggedUserRoles, JSON.stringify(auth.roles), { expires: new Date(auth.validity) });

    this.authToken = auth.token;
    this.authEntity = auth.entity;
    this.loggedUserRoles = new Set(auth.roles);

    this.notifyAuthListeners(auth);
  }

  clearPlayerAuth() {
    Cookies.remove(this.CK_authToken)
    this.authToken = null;
    Cookies.remove(this.CK_authEntity)
    this.authEntity = null;
    Cookies.remove(this.CK_loggedUserRoles)
    this.loggedUserRoles = null;

    this.notifyAuthListeners(null);
  }

  // Below are the API calls

  // Public Server
  getServerPublicInfo(): Promise<GameServerPublicInfo> {
    return new Promise<GameServerPublicInfo>((resolve, reject) => {
      axios.get(`${this.backendUrl}${BackendClient.GetServerPublicInfoPath}`)
        .then((response) => resolve(response.data))
        .catch((reason) => console.log(`Exception when fetching public info: ${JSON.stringify(reason)}`));
    });
  }

  // User Public
  tryToDoLoginWithSteamReturnInfo(steamReturnInfo: string): Promise<CommonResponseWithPlayerAuthInfo> {
    return new Promise<CommonResponseWithPlayerAuthInfo>((resolve, reject) => {
      axios.get(`${this.backendUrl}${BackendClient.LoginWithSteamReturnInfoPath}${steamReturnInfo}`)
        .then((response) => resolve(response.data))
        .catch((reason) => {
          console.log(`Exception when trying to do login with steam return info: ${JSON.stringify(reason)} - ${reason}`)
          reject(reason);
        });
    });
  }

  setServerRunningValues(debugFlag: boolean): Promise<CommonResponse> {
    const headers = {
      'Authorization': `${this.authEntity} ${this.authToken}`,
    };

    return new Promise<CommonResponse>((resolve, reject) => {
      axios.post(`${this.backendUrl}${BackendClient.SetServerRunningValues}`, {debugFlag: debugFlag}, {headers: headers})
        .then((response) => resolve(response.data))
        .catch((reason) => {
          console.log(`Exception when trying to set running values: ${JSON.stringify(reason)}`)
          reject(reason);
        });
    });
  }

  getPlayingPlayers(): Promise<PlayingPlayersResponse> {
    const headers = {
      'Authorization': `${this.authEntity} ${this.authToken}`,
    };

    return new Promise<PlayingPlayersResponse>((resolve, reject) => {
      axios.get(`${this.backendUrl}${BackendClient.ListPlayingPlayers}`, {headers: headers})
        .then((response) => resolve(response.data))
        .catch((reason) => {
          console.log(`Exception when trying to get playing players: ${JSON.stringify(reason)}`)
          reject(reason);
        });
    });
  }

  listTerritories(includeDeleted: boolean): Promise<Array<PublicTerritoryDTO>> {
    const headers = {
      'Authorization': `${this.authEntity} ${this.authToken}`,
    };

    return new Promise<Array<PublicTerritoryDTO>>((resolve, reject) => {
      axios.get(`${this.backendUrl}${BackendClient.ListTerritories}`, {headers: headers, params: {includeDeleted: includeDeleted}})
        .then((response) => resolve(response.data))
        .catch((reason) => {
          console.log(`Exception when trying to listTerritories: ${JSON.stringify(reason)}`)
          reject(reason);
        });
    });
  }

  // User Private
  retrieveMySteamInfo(): Promise<CommonResponseWithSteamPlayerPublicInfo> {
    const headers = {
      'Authorization': `${this.authEntity} ${this.authToken}`,
    };

    return new Promise<CommonResponseWithSteamPlayerPublicInfo>((resolve, reject) => {
      axios.get(`${this.backendUrl}${BackendClient.RetrieveMySteamInfo}`, {headers: headers})
        .then((response) => resolve(response.data))
        .catch((reason) => {
          console.log(`Exception when trying to retrieve my steam info: ${JSON.stringify(reason)}`)
          reject(reason);
        });
    });
  }

  repairMyBag(): Promise<CommonResponse> {
    const headers = {
      'Authorization': `${this.authEntity} ${this.authToken}`,
    };

    return new Promise<CommonResponse>((resolve, reject) => {
      axios.post(`${this.backendUrl}${BackendClient.RepairMyVipBag}`, {}, {headers: headers})
        .then((response) => resolve(response.data))
        .catch((reason) => {
          console.log(`Exception when trying to repair my bag: ${JSON.stringify(reason)}`)
          reject(reason);
        });
    });
  }

  getMyTeleportingPOIs(): Promise<PlayerAvailablePOIsDTO> {
    const headers = {
      'Authorization': `${this.authEntity} ${this.authToken}`,
    };

    return new Promise<PlayerAvailablePOIsDTO>((resolve, reject) => {
      axios.get(`${this.backendUrl}${BackendClient.GetMyTeleportingPOIs}`, {headers: headers})
        .then((response) => resolve(response.data))
        .catch((reason) => {
          console.log(`Exception when trying to get my player available pois: ${JSON.stringify(reason)}`)
          reject(reason);
        });
    });
  }

  amIAtATeleportingPOI(): Promise<OptionalGenericPOIDTO> {
    const headers = {
      'Authorization': `${this.authEntity} ${this.authToken}`,
    };

    return new Promise<OptionalGenericPOIDTO>((resolve, reject) => {
      axios.get(`${this.backendUrl}${BackendClient.AmIAtATeleportingPOI}`, {headers: headers})
        .then((response) => resolve(response.data))
        .catch((reason) => {
          console.log(`Exception when trying to get am i at a teleporting poi: ${JSON.stringify(reason)}`)
          reject(reason);
        });
    });
  }

  doTeleport(request: DoTeleportRequestDTO): Promise<CommonResponse> {
    const headers = {
      'Authorization': `${this.authEntity} ${this.authToken}`,
    };

    return new Promise<CommonResponse>((resolve, reject) => {
      axios.post(`${this.backendUrl}${BackendClient.DoTeleport}`, request ,{headers: headers})
        .then((response) => resolve(response.data))
        .catch((reason) => {
          console.log(`Exception when trying to teleport: ${JSON.stringify(reason)}`)
          reject(reason);
        });
    });
  }

  setTerritorySpawnLocation(): Promise<CommonResponse> {
    const headers = {
      'Authorization': `${this.authEntity} ${this.authToken}`,
    };

    return new Promise<CommonResponse>((resolve, reject) => {
      axios.post(`${this.backendUrl}${BackendClient.SetTerritorySpawnLocation}`, {} ,{headers: headers})
        .then((response) => resolve(response.data))
        .catch((reason) => {
          console.log(`Exception when trying to setTerritorySpawnLocation: ${JSON.stringify(reason)}`)
          reject(reason);
        });
    });
  }

  // User Private (Quester)
  getQuests(): Promise<Array<ExpansionQuestDTO>> {
    const headers = {
      'Authorization': `${this.authEntity} ${this.authToken}`,
    };

    return new Promise<Array<ExpansionQuestDTO>>((resolve, reject) => {
      axios.get(`${this.backendUrl}${BackendClient.GetQuests}`, {headers: headers})
        .then((response) => resolve(response.data))
        .catch((reason) => {
          console.log(`Exception when getting quests: ${JSON.stringify(reason)}`)
          reject(reason);
        });
    });
  }

  getQuestForEdit(questId: number): Promise<ExpansionQuestDTO> {
    const headers = {
      'Authorization': `${this.authEntity} ${this.authToken}`,
    };

    return new Promise<ExpansionQuestDTO>((resolve, reject) => {
      axios.get(`${this.backendUrl}${BackendClient.GetQuestForEdit}`, {headers: headers, params: {id: questId}})
        .then((response) => resolve(response.data))
        .catch((reason) => {
          console.log(`Exception when getting quest: ${JSON.stringify(reason)}`)
          reject(reason);
        });
    });
  }

  getNPCs(): Promise<Array<ExpansionQuestNPCDTO>> {
    const headers = {
      'Authorization': `${this.authEntity} ${this.authToken}`,
    };

    return new Promise<Array<ExpansionQuestNPCDTO>>((resolve, reject) => {
      axios.get(`${this.backendUrl}${BackendClient.GetNPCs}`, {headers: headers})
        .then((response) => resolve(response.data))
        .catch((reason) => {
          console.log(`Exception when getting quest npcs: ${JSON.stringify(reason)}`)
          reject(reason);
        });
    });
  }

  // User Private (Admin)
  triggerRebootServer(reason: string): Promise<CommonResponse> {
    const headers = {
      'Authorization': `${this.authEntity} ${this.authToken}`,
    };

    return new Promise<CommonResponse>((resolve, reject) => {
      axios.post(`${this.backendUrl}${BackendClient.TriggerRebootPath}`, {reason: reason}, {headers: headers})
        .then((response) => resolve(response.data))
        .catch((reason) => {
          console.log(`Exception when trying to trigger reboot: ${JSON.stringify(reason)}`)
          reject(reason);
        });
    });
  }
}

export default BackendClient;