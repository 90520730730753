import {Icon} from "leaflet";

export class IconTerritory {
  static getIcon(): Icon {
    return new Icon({
      iconUrl: '/images/icons/territory.png',
      iconSize: [30, 30],
      myString: 'This is my string',
    });
  }
}