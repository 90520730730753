import React, {useState} from 'react';
import {Button, Input, InputNumber, Modal, Switch, Tooltip} from "antd";
import ExpansionQuestObjectiveAICampDTO
  from "../../../backend/models/quest/objectives/aicamp/ExpansionQuestObjectiveAICampDTO";
import ExpansionQuestObjectiveDTO from "../../../backend/models/quest/objectives/ExpansionQuestObjectiveDTO";
import StringListEditor from "../../listsEditor/StringListEditor";
import {EditOutlined} from "@ant-design/icons";
import ExpansionQuestObjectiveAICampSpawnsEditor from "../npc/ExpansionQuestObjectiveAICampSpawnsEditor";
import ExpansionQuestObjectiveAICampAISpawnDTO
  from "../../../backend/models/quest/objectives/aicamp/ExpansionQuestObjectiveAICampAISpawnDTO";

interface ExpansionQuestObjectiveAICampComponentProps {
  objective: ExpansionQuestObjectiveAICampDTO;
  onObjectiveChange: (updatedObjective: ExpansionQuestObjectiveDTO) => void;
}

function ExpansionQuestObjectiveAICampComponent({objective, onObjectiveChange}: ExpansionQuestObjectiveAICampComponentProps) {
  const [allowedWeaponsModalOpen, setAllowedWeaponsModalOpen] = useState(false);
  const [allowedDamageZonesModalOpen, setAllowedDamageZonesModalOpen] = useState(false);
  const [spawnsModalOpen, setSpawnsModalOpen] = useState(false);

  const handleObjectiveTextChanges = (e: React.ChangeEvent<HTMLInputElement>) => {
    const updateObjective: ExpansionQuestObjectiveAICampDTO = ({...objective!!, objectiveText: e.target.value});
    onObjectiveChange(updateObjective);
  };
  const handleTimeLimitChanges = (value: number | null) => {
    const updateObjective: ExpansionQuestObjectiveAICampDTO = ({...objective!!, timeLimit: value ?? -1});
    onObjectiveChange(updateObjective);
  };
  const handleInfectedDeletionRadiusChanges = (value: number | null) => {
    const updateObjective: ExpansionQuestObjectiveAICampDTO = ({...objective!!, infectedDeletionRadius: value ?? -1});
    onObjectiveChange(updateObjective);
  };
  const handleMaxDistanceChanges = (value: number | null) => {
    const updateObjective: ExpansionQuestObjectiveAICampDTO = ({...objective!!, maxDistance: value ?? -1});
    onObjectiveChange(updateObjective);
  };
  const handleMinDistanceChanges = (value: number | null) => {
    const updateObjective: ExpansionQuestObjectiveAICampDTO = ({...objective!!, minDistance: value ?? -1});
    onObjectiveChange(updateObjective);
  };
  const handleAllowedWeaponsChanges = (updatedList: string[]) => {
    const updateObjective: ExpansionQuestObjectiveAICampDTO = ({...objective!!, allowedWeapons: updatedList});
    onObjectiveChange(updateObjective);
  };
  const handleAllowedDamageZonesChanges = (updatedList: string[]) => {
    const updateObjective: ExpansionQuestObjectiveAICampDTO = ({...objective!!, allowedDamageZones: updatedList});
    onObjectiveChange(updateObjective);
  };

  const handleSpawnsChanges = (updatedList: ExpansionQuestObjectiveAICampAISpawnDTO[]) => {
    const updateObjective: ExpansionQuestObjectiveAICampDTO = ({...objective!!, aiSpawns: updatedList});
    onObjectiveChange(updateObjective);
  };
  const handleActiveChanges = (checked: boolean) => {
    const updateObjective: ExpansionQuestObjectiveAICampDTO = ({...objective!!, active: checked});
    onObjectiveChange(updateObjective);
  };

  return (
    <div style={{textAlign: "left"}}>
      <table>
        <tbody>
        <tr>
          <td><Tooltip title="Não mexer"> Config version </Tooltip></td>
          <td><Input disabled={true} value={objective?.configVersion} size={"large"} htmlSize={70}/></td>
        </tr>
        <tr>
          <td><Tooltip title="Id do objectivo. Não mexer"> Objective id </Tooltip></td>
          <td><Input disabled={true} value={objective?.id} size={"large"} htmlSize={50}/></td>
        </tr>
        <tr>
          <td><Tooltip title="Texto do objetivo"> Objective text </Tooltip></td>
          <td><Input value={objective?.objectiveText} size={"large"} htmlSize={50} onChange={handleObjectiveTextChanges}/></td>
        </tr>
        <tr>
          <td><Tooltip title="Limite em segundos para conclusão do objetivo. -1 -> sem limite"> Time limit </Tooltip></td>
          <td><InputNumber value={objective?.timeLimit} min={-1} max={999999} onChange={handleTimeLimitChanges}/></td>
        </tr>
        <tr>
          <td><Tooltip title="Distância em metros em que os zumbis serão deletados"> Infected deletion radius </Tooltip></td>
          <td><InputNumber value={objective?.infectedDeletionRadius} min={0} max={999999} onChange={handleInfectedDeletionRadiusChanges}/></td>
        </tr>
        <tr>
          <td><Tooltip title="Distância máxima que o player pode ficar do objetivo. Se ele sair, o objetivo falha. -1 -> desabilita verificação"> Max distance </Tooltip></td>
          <td><InputNumber value={objective?.maxDistance} min={-1} max={999999} onChange={handleMaxDistanceChanges}/></td>
        </tr>
        <tr>
          <td><Tooltip title="Distância mínima que o player precisa ficar do objetivo. Se ele se aproximar, o objetivo falha. -1 -> desabilita verificação"> Min distance </Tooltip></td>
          <td><InputNumber value={objective?.maxDistance} min={-1} max={999999} onChange={handleMinDistanceChanges}/></td>
        </tr>
        <tr>
          <td><Tooltip title="Só podem ser utilizadas essas armas para concluir o objetivo"> Allowed weapons </Tooltip></td>
          <td><Input value={JSON.stringify(objective?.allowedWeapons)} disabled={true}/></td>
          <td><Button type="primary" icon={<EditOutlined/>} size={"middle"} onClick={() => {setAllowedWeaponsModalOpen(true)}}/></td>
        </tr>
        <tr>
          <td><Tooltip title="Apenas danos nessas regiões do corpo contam para completar o objetivo"> Allowed damage zones </Tooltip></td>
          <td><Input value={JSON.stringify(objective?.allowedDamageZones)} disabled={true}/></td>
          <td><Button type="primary" icon={<EditOutlined/>} size={"middle"} onClick={() => {setAllowedDamageZonesModalOpen(true)}}/></td>
        </tr>
        <tr>
          <td><Tooltip title="Bots que farão parte da quest"> AI Spawns </Tooltip></td>
          <td><Input value={JSON.stringify(objective?.aiSpawns)} disabled={true}/></td>
          <td><Button type="primary" icon={<EditOutlined/>} size={"middle"} onClick={() => {setSpawnsModalOpen(true)}}/></td>
        </tr>
        <tr>
          <td><Tooltip title="Se o objetivo está ativo (se vai aparecer na quest)"> Active </Tooltip></td>
          <td><Switch checked={objective?.active} onChange={handleActiveChanges}/></td>
        </tr>
        </tbody>
      </table>

      <Modal open={allowedWeaponsModalOpen} centered={true} closable={false}
             cancelButtonProps={{ style: { display: 'none' } }}
             onOk={() => {setAllowedWeaponsModalOpen(false)}}
      >
        <StringListEditor strings={objective.allowedWeapons ?? []} onStringsChange={handleAllowedWeaponsChanges} />
      </Modal>
      <Modal open={allowedDamageZonesModalOpen} centered={true} closable={false}
             cancelButtonProps={{ style: { display: 'none' } }}
             onOk={() => {setAllowedDamageZonesModalOpen(false)}}
      >
        <StringListEditor strings={objective.allowedDamageZones ?? []} onStringsChange={handleAllowedDamageZonesChanges} />
      </Modal>
      <Modal open={spawnsModalOpen} centered={true} closable={false}
             cancelButtonProps={{ style: { display: 'none' } }}
             onOk={() => {setSpawnsModalOpen(false)}}
      >
        <ExpansionQuestObjectiveAICampSpawnsEditor spawns={objective.aiSpawns ?? []} onSpawnsChange={handleSpawnsChanges} />
      </Modal>
    </div>


);
}

export default ExpansionQuestObjectiveAICampComponent;