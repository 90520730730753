import ExpansionQuestObjectiveWrapDTO from "../ExpansionQuestObjectiveWrapDTO";
import ExpansionObjectiveCode from "./ExpansionObjectiveCode";
import ExpansionQuestObjectiveAICampDTO from "./aicamp/ExpansionQuestObjectiveAICampDTO";

class ExpansionQuestObjectiveDTO {
  id!: number;
  objectiveType!: number;


  static fromRawObject(rawObj: ExpansionQuestObjectiveWrapDTO): ExpansionQuestObjectiveDTO | undefined {
    switch (rawObj.type) {
      case ExpansionObjectiveCode.AICamp:
        return JSON.parse(rawObj.rawObjective) as ExpansionQuestObjectiveAICampDTO;
      default:
        return undefined;
    }
  }
}

export default ExpansionQuestObjectiveDTO;