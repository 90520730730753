import React from 'react';
import {Button} from "antd";
import {DeleteOutlined, PlusCircleOutlined} from "@ant-design/icons";
import ExpansionQuestObjectiveAICampAISpawnDTO
  from "../../../backend/models/quest/objectives/aicamp/ExpansionQuestObjectiveAICampAISpawnDTO";
import {Collapse, CollapseProps} from "antd/lib";
import ExpansionQuestObjectiveAICampSpawnEditor from "./ExpansionQuestObjectiveAICampSpawnEditor";


interface ExpansionQuestObjectiveAICampSpawnsEditorProps {
  spawns: ExpansionQuestObjectiveAICampAISpawnDTO[];
  onSpawnsChange: (updatedSpawns: ExpansionQuestObjectiveAICampAISpawnDTO[]) => void;
}

function ExpansionQuestObjectiveAICampSpawnsEditor({ spawns, onSpawnsChange }: ExpansionQuestObjectiveAICampSpawnsEditorProps) {
  const addNewSpawn = () => {
    onSpawnsChange([...spawns, {name: '', persist: true, faction: "", formation: "", formationLooseness: 5.0, loadout: "", units: [], numberOfAI: 1, behaviour: "", speed: "",
      underThreatSpeed: "", canBeLooted: true, unlimitedReload: true, sniperProneDistanceThreshold: 300, accuracyMin: -1, accuracyMax: -1, threatDistanceLimit: 1000,
      noiseInvestigationDistanceLimit: 1000, damageMultiplier: 1.0, damageReceivedMultiplier: 1.0, minDistRadius: 500, maxDistRadius: 1000, despawnRadius: 1100, minSpreadRadius: 10, maxSpreadRadius: 50, chance: 1.0,
      waypointInterpolation: "", despawnTime: 300, respawnTime: 600, useRandomWaypointAsStartPoint: true, waypoints: []}]);
  };

  const deleteRow = (index: number) => {
    const updatedSpawns = [...spawns];
    updatedSpawns.splice(index, 1);
    onSpawnsChange(updatedSpawns);
  };

  const onSpawnChange = (index: number) => (updatedSpawn: ExpansionQuestObjectiveAICampAISpawnDTO) => {
    const updatedSpawns = [...spawns];
    updatedSpawns[index] = updatedSpawn;
    onSpawnsChange(updatedSpawns);
  };

  const buildItemsFromSpawns = () =>{
    const collapseItem: CollapseProps['items'] = [];
    for (let i = 0; i < spawns.length; i++) {
      const spawn = spawns[i];
      collapseItem.push({
        key: i.toString(),
        label: "Spawn " + (i + 1),
        children:
          <div>
            <Button type="primary" icon={<DeleteOutlined/>} size={"middle"} onClick={() => {deleteRow(i)}} style={{backgroundColor: "red", marginLeft: 4}}/>
            <ExpansionQuestObjectiveAICampSpawnEditor spawn={spawn} onSpawnChange={onSpawnChange(i)} />
          </div>
      });
    }

    return collapseItem;
  };

  return (
    <div style={{textAlign: "center"}}>
      <Button type="primary" icon={<PlusCircleOutlined />} size={"middle"} onClick={addNewSpawn} style={{marginBottom: 16}} />

      <Collapse style={{marginTop: 8}} items={buildItemsFromSpawns()} />
    </div>
);
}

export default ExpansionQuestObjectiveAICampSpawnsEditor;