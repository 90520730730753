import React from 'react';
import {ItemPresenter} from "../ItemPresenter";
import {Button, Divider, Input} from "antd";
import {PlusCircleOutlined, DeleteOutlined} from "@ant-design/icons";

interface StringListEditorProps {
  strings: string[];
  onStringsChange: (updatedStrings: string[]) => void;
}

function StringListEditor({ strings, onStringsChange }: StringListEditorProps) {
  const addNewRow = () => {
    onStringsChange([...strings, '']);
  };
  const deleteRow = (index: number) => {
    const updatedStrings = [...strings];
    updatedStrings.splice(index, 1);
    onStringsChange(updatedStrings);
  };
  const handleInputChange = (index: number) => (e: React.ChangeEvent<HTMLInputElement>) => {
    const updatedStrings = [...strings];
    updatedStrings[index] = e.target.value;
    onStringsChange(updatedStrings);
  };

  return (
    <div style={{textAlign: "center"}}>
      <Button type="primary" icon={<PlusCircleOutlined />} size={"middle"} onClick={addNewRow} style={{marginBottom: 16}} />
      {strings.map((str, index) => (
        <div key={index}>
          <div  style={{ display: 'flex', alignItems: 'center' }}>
            <Input
              key={index}
              value={str}
              onChange={handleInputChange(index)}
            />
            <Button type="primary" icon={<DeleteOutlined />} size={"middle"} onClick={() => {deleteRow(index)}} style={{backgroundColor: "red", marginLeft: 4}} />
          </div>
          <Divider style={{margin: 8}}/>
        </div>
      ))}
    </div>
  );
}

export default StringListEditor;