import React from 'react';
import {InputNumber} from "antd";

interface IntListEditorProps {
  coords: number[];
  onCoordsChange: (updatedCoords: number[]) => void;
}

function WaypointEditor({ coords, onCoordsChange }: IntListEditorProps) {
  const handleInputChange = (index: number) => (value: number | null) => {
    const updatedCoords = [...coords];
    updatedCoords[index] = value ?? 0;
    onCoordsChange(updatedCoords);
  };

  return (
    <div style={{textAlign: "center"}}>
      <InputNumber value={coords[0]} min={0} max={99999999} step={0.01} onChange={handleInputChange(0)} />
      <InputNumber value={coords[1]} min={0} max={99999999} step={0.01} onChange={handleInputChange(1)} />
      <InputNumber value={coords[2]} min={0} max={99999999} step={0.01} onChange={handleInputChange(2)} />
    </div>
  );
}

export default WaypointEditor;